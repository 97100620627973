var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Placeholder & Initial Open Date"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePlaceholder) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Add custom placeholder text to the control, when no date is selected, via the ")]), _c('code', [_vm._v("placeholder")]), _c('span', [_vm._v(" prop. ")]), _c('span', [_vm._v("You can change this behaviour by specifying a date via the ")]), _c('code', [_vm._v("initial-date")]), _c('span', [_vm._v(" prop.")])]), _c('label', {
    attrs: {
      "for": "datepicker-placeholder"
    }
  }, [_vm._v("Date picker with placeholder")]), _c('b-form-datepicker', {
    attrs: {
      "id": "datepicker-placeholder",
      "placeholder": "Choose a date",
      "local": "en",
      "initial-date": "2020-06-16"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }