var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Date string format"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeString) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To change format options of the displayed date text inside the component, e.g. in the header or placeholder, set the ")]), _c('code', [_vm._v("date-format-options")]), _c('span', [_vm._v(" prop to an object containing the requested format properties for the")]), _c('code', [_vm._v("Intl.DateTimeFormat")]), _c('span', [_vm._v(" object.")])]), _c('div', [_c('label', {
    attrs: {
      "for": "datepicker-dateformat1"
    }
  }, [_vm._v("Custom date format")]), _c('b-form-datepicker', {
    attrs: {
      "id": "datepicker-dateformat1",
      "date-format-options": {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short'
      },
      "locale": "en"
    }
  }), _c('label', {
    staticClass: "mt-2",
    attrs: {
      "for": "datepicker-dateformat2"
    }
  }, [_vm._v("Short date format")]), _c('b-form-datepicker', {
    attrs: {
      "id": "datepicker-dateformat2",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "locale": "en"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }