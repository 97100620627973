var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Optional controls"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeOptional) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Add optional control buttons to the bottom of the calendar popup via the props ")]), _c('code', [_vm._v("today-button")]), _c('span', [_vm._v(",")]), _c('code', [_vm._v("reset-button")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("close-button")]), _c('span', [_vm._v(".")])]), _c('label', {
    attrs: {
      "for": "datepicker-buttons"
    }
  }, [_vm._v("Date picker with optional footer buttons")]), _c('b-form-datepicker', {
    attrs: {
      "id": "datepicker-buttons",
      "today-button": "",
      "reset-button": "",
      "close-button": "",
      "locale": "en",
      "menu-class": "w-100",
      "calendar-width": "100%"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }