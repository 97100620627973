var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Full width calendar dropdown"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFullWith) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To create a full width calendar dropdown simply set the ")]), _c('code', [_vm._v("menu-class")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("'w-100'")]), _c('span', [_vm._v(" and set the calendar-width prop to '100%':")])]), _c('label', {
    attrs: {
      "for": "datepicker-full-width"
    }
  }, [_vm._v("Choose a date")]), _c('b-form-datepicker', {
    attrs: {
      "id": "datepicker-full-width",
      "menu-class": "w-100",
      "calendar-width": "100%"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }