var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Button only mode"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeButtonOnly) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("button-only")]), _c('span', [_vm._v(" prop to render the datepicker as a dropdown button. The formatted date label will be rendered with the class ")]), _c('code', [_vm._v("sr-only")]), _c('span', [_vm._v(" (available only to screen readers).")])]), _c('label', {
    attrs: {
      "for": "example-input"
    }
  }, [_vm._v("Choose a date")]), _c('b-input-group', {
    staticClass: "mb-1"
  }, [_c('b-form-input', {
    attrs: {
      "id": "example-input",
      "type": "text",
      "placeholder": "YYYY-MM-DD",
      "autocomplete": "off",
      "show-decade-nav": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-input-group-append', [_c('b-form-datepicker', {
    attrs: {
      "show-decade-nav": "",
      "button-only": "",
      "button-variant": "outline-primary",
      "right": "",
      "size": "sm",
      "locale": "en-US",
      "aria-controls": "example-input"
    },
    on: {
      "context": _vm.onContext
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1), _c('b-card-text', {
    staticClass: "mb-1"
  }, [_vm._v(" Value: '" + _vm._s(_vm.value) + "' ")]), _c('b-card-text', {
    staticClass: "mb-1"
  }, [_vm._v(" Selected: '" + _vm._s(_vm.selected) + "' ")]), _c('span', [_vm._v("Formatted: '" + _vm._s(_vm.formatted) + "'")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }